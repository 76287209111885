import React from "react"
import Layout from "../components/layout"
import { Link, graphql, useStaticQuery } from "gatsby"
import "../styles/pages/blog.scss"
import Head from "../components/head"
import { GatsbyImage } from "gatsby-plugin-image"

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          author
          description
          image
        }
      }
      contentfulAuthor(fullName: { eq: "Lasse Rosendahl Ravn" }) {
        id
        profileImage {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <Layout>
      <Head
        title={`${data.site.siteMetadata.author} | A personal site and blog`}
        description={`${data.site.siteMetadata.description}`}
      />
      <div className="my-4 sm:my-12 px-4">
        <div className="mb-8">
          <GatsbyImage
            className="rounded-md w-24"
            image={data.contentfulAuthor.profileImage.gatsbyImageData}
            alt={data.site.siteMetadata.author}
          />
        </div>
        <h1 className="text-zinc-200 text-4xl sm:text-5xl leading-tight font-bold">
          Hi! I'm Lasse 🙋‍♂️ <br />
        </h1>
        <p className="mt-6 text-zinc-400 text-xl">Why write and share?</p>

        <ol className="list-decimal mt-4 prose-li:ml-6 text-white">
          <li>
            Forcing myself to write (and publish) drastically improves clarity
          </li>
          <li>
            Setting personal goals, and publicly sharing them, increases the
            likelihood that I accomplish them
          </li>
          <li>
            Ever since I started sharing stuff on LinkedIn i've grown my network
            more than anything else
          </li>
        </ol>
        <div className="mt-6 flex">
          <Link
            to={`/blog/`}
            className="text-indigo-50 active:text-indigo-400 font-semibold h-12 px-6 py-3 rounded-md  bg-indigo-700 hover:bg-indigo-600 active:bg-indigo-700"
          >
            Read what I write!
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPage
